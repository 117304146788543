import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import "./addmn.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../img/1.png";
import { useEffect, useState } from "react";
import axios from "axios";
import pending from "../../img/pending.png";
import success from "../../img/checked.png";
import error from "../../img/close.png";

function BienDong() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [profile, setProfile] = useState(null);
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");
			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		axios
			.get(`https://server.trainingsky.city/auth/biendongsodu`, {})
			.then((res) => {
				setData(res.data.data);
			})
			.catch((err) => function () {});
		axios
            .get(`https://server.trainingsky.city/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
	}, []);
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "#000",
								}}
							>
								Biến động số dư
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				{data ? (
					<div className="content_profile" style={{ padding: "0 0 95px" }}>
						{data?.map((item, key) => (
							<>
								<div key={key} className="history_payment">
									<div style={{ display: "flex" }}>
										{item.type_payment === "NẠP" ? (
											<img
												src={success}
												style={{
													width: "40px",
													height: "40px",
													backgroundColor: "white",
												}}
											/>
										) : null}

										{item.type_payment === "RÚT" ? (
											<img
												src={error}
												style={{
													width: "40px",
													height: "40px",
													backgroundColor: "white",
												}}
											/>
										) : null}
										{item.status_bet === "Lose" ? (
											<img
												src={error}
												style={{
													width: "40px",
													height: "40px",
													backgroundColor: "white",
												}}
											/>
										) : null}
										{item.status_bet === "Win" ? (
											<img
												src={success}
												style={{
													width: "40px",
													height: "40px",
													backgroundColor: "white",
												}}
											/>
										) : null}
										{item.status ? (
											<img
												src={pending}
												style={{
													width: "40px",
													height: "40px",
													backgroundColor: "white",
												}}
											/>
										) : null}
										{item.status_bet === "Pending" ? null : null}

										{/* {item.status_payment === "Deny" ? (
											<img
												src={error}
												style={{
													width: "40px",
													height: "40px",
													backgroundColor: "white",
												}}
											/>
										) : null} */}
										<div className="type_payment">
											<div className="typepayment_detail">
												{item.type_payment != "Pending"
													? item.type_payment
													: null}
												{item?.bet == 1 ? "IN" : item?.bet == 2 ? "OUT" : item?.bet == 3 ? "UP" : item?.bet == 4 ? "DOWN" : "Lỗi"}
												{item.status ? "THƯỞNG" : null}
											</div>
											<div className="date_time">
												{item.status_bet != "Pending"
													? formatDate(new Date(item.createdAt))
													: null}
											</div>
										</div>
									</div>
									{item.type_payment === "RÚT" ? (
										<div style={{ color: "red" }} className="money_pamn">
											+{item.money}
										</div>
									) : null}
									{item.type_payment === "NẠP" ? (
										<div style={{ color: "green" }} className="money_pamn">
											+{item.money}
										</div>
									) : null}
									{item.status_bet === "Win" ? (
										<div style={{ color: "green" }} className="money_pamn">
											+{item.money}
										</div>
									) : null}
									{item.status_bet === "Lose" ? (
										<div style={{ color: "red" }} className="money_pamn">
											-{item.money}
										</div>
									) : null}
									{item.status ? (
										<div style={{ color: "green" }} className="money_pamn">
											+{Number(item.money)}
										</div>
									) : null}
								</div>
								<hr className="line-pay" />
							</>
						))}
					</div>
				) : (
					<div style={{ margin: "10px 0 0" }}>
						Hiện tại chưa có giao dịch nào
					</div>
				)}

				<Footer profile={profile}/>
			</div>
		</>
	);
}
export default BienDong;
