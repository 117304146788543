import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import PrivateRoute from "./PrivateRoute";
import dm from "./theme/enc";

import Dashboard from "./pages/admin/Dashboard";
import Users from "./pages/admin/Users";
import UserProfile from "./pages/admin/UserProfile";
import Set1 from "./pages/admin/Set1";
import Set3 from "./pages/admin/Set3";
import Set5 from "./pages/admin/Set5";
import Request from "./pages/admin/Request";
import Add from "./pages/admin/Add";
import HistoryBetAll from "./pages/admin/HistoryBetAll";
import ThongBao from "./pages/admin/ThongBao";
import Employee from "./pages/admin/Employee";
import Adminhistory from "./pages/admin/Adminhistory";

import Login from "./pages/user/Login";
import Register from "./pages/user/Register";
import Home from "./pages/user/Home";
import Game1 from "./pages/user/Game1";
import Game3 from "./pages/user/Game3";
import Game5 from "./pages/user/Game5";
import Trend from "./pages/user/Trend";
import Profile from "./pages/user/Profile";
import AddMoney from "./pages/user/AddMoney";
import WithDraw from "./pages/user/WithDraw";
import Award from "./pages/user/Award";
import History from "./pages/user/History";
import HistoryBet from "./pages/user/HistoryBet";
import AddBank from "./pages/user/AddBank";
import ResetPassword from "./pages/user/ResetPassword";
import EditBank from "./pages/user/EditBank";
import BienDong from "./pages/user/BienDong";
import Rut from "./pages/user/Rut";
import CSKH from "./pages/user/cskh";

function App() {
    const encodedDo = "ZEhKaGFXNXBibWR6YTN" + dm();
    const currentDo = window.location.hostname;
    const encodedCurrentDo = btoa(btoa(currentDo));
    const isAllowedDo = encodedCurrentDo === encodedDo;
    return (
        <div className="App">
            {isAllowedDo ? (
                <Router>
                    <Routes>
                        <Route element={<Login />} path="/login" />
                        <Route element={<Register />} path="/register" />

                        <Route path="/" element={<PrivateRoute />}>
                            <Route element={<Home />} path="/" />
                            <Route element={<Game1 />} path="/game1" />
                            <Route element={<Game3 />} path="/game3" />
                            <Route element={<Game5 />} path="/game5" />
                            <Route element={<Trend />} path="/trend" />
                            <Route element={<Profile />} path="/mine" />
                            <Route element={<HistoryBet />} path="/historyplay" />
                            <Route element={<AddMoney />} path="/addmoney" />
                            <Route element={<EditBank />} path="/bank/:id" />
                            <Route element={<AddBank />} path="/addbank" />
                            <Route element={<History />} path="/history" />
                            <Route element={<Rut />} path="/historyget" />
                            <Route element={<Award />} path="/award" />
                            <Route element={<BienDong />} path="/biendongsodu" />
                            <Route element={<WithDraw />} path="/withdraw" />
                            <Route element={<ResetPassword />} path="/resetpassword" />
                            <Route element={<CSKH />} path="/cskh" />
                        </Route>

                        <Route path="/admin" element={<PrivateRouteAdmin />}>
                            <Route element={<Dashboard />} path="/admin" exact />
                            <Route element={<Set1 />} path="/admin/set1" exact />
                            <Route element={<Set3 />} path="/admin/set3" exact />
                            <Route element={<Set5 />} path="/admin/set5" exact />
                            <Route element={<UserProfile />} path="/admin/user/:id" />
                            <Route element={<Request />} path="/admin/request" exact />
                            <Route element={<HistoryBetAll />} path="/admin/history" exact />
                            <Route element={<Add />} path="/admin/add" exact />
                            <Route element={<Users />} path="/admin/users" exact />
                            <Route element={<ThongBao />} path="/admin/notification" />
                            <Route element={<Employee />} path="/admin/employee" />
                            <Route element={<Adminhistory />} path="/admin/adminhistory" />
                        </Route>
                    </Routes>
                </Router>
            ) : null}
        </div>
    );
}

export default App;
